import {Layout, Tabs, Icon, Typography, Select, Button, Popconfirm, Modal, Badge} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Metrics} from 'components/Metrics';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {Alarm as AlarmComponent} from 'containers/Alarm';
import {FarmLink} from '../../components/Atoms/Links';
import {FarmInformation} from '../../components/FarmInformation/FarmInformation';
import {ModernFarmInformation} from '../../components/FarmInformation/ModernFarmInformation';
import {cycleName} from '../../utils';
import {closeCycle, getFarmCycles, getFarmInfo, openCycle, setSelectedCycle} from '../../reduxStore/modules/house';
// import {Task} from '../Tasks';
import {BreadcrumbsFarm} from './Breadcrumbs';
import {MultiMetrics} from '../../components/Metrics/MultiMetrics';
import {Route, Switch, useRouteMatch} from "react-router-dom";

const {Option} = Select;
const {TabPane} = Tabs;

const {Content} = Layout;
const {Title} = Typography;

const StyledContent = styled(Content)`
    padding: 16px 0;
    margin: 0;
    min-height: 280px;

    & > .ant-tabs-card > .ant-tabs-content {
        height: 100%;
        margin-top: -16px;
    }

    & > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    & > .ant-tabs-card > .ant-tabs-bar {
        border-color: #fff;
    }

    & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
        border-color: transparent;
        background: transparent;
    }

    & > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
    }
`;

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
`;

const HeaderBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const HouseRaw = props => {
    const {units, actions, match, farmInfo, cycles, cycle} = props;

    const [previousFarmId, setPreviousFarmId] = useState();
    const [nextFarmId, setNextFarmId] = useState();
    let { path, url } = useRouteMatch();

    const setUpCycle = cycleEl => {
        window.selectedCycle = cycleEl;
        props.actions.setSelectedCycle(cycleEl.id);
    };

    const handleCycleChange = cycleId => {
        const cycleEl = find(cycles.data.items, ['id', cycleId]);

        setUpCycle(cycleEl);
    };

    const refresh = () => {
        actions.getFarmInfo(match.params.farmId);
        actions
            .getFarmCycles(match.params.farmId)
            .then(data => {
                if (data.value.items.length) {
                    setUpCycle(data.value.items[0]);
                }
            })
            .catch(err => {
                Modal.error({
                    title: 'В процессе подключения',
                    content: 'Пожалуйста, попробуйте позднее или свяжитесь с нашей службой поддержки.',
                });
            });
    };

    useEffect(() => {
        refresh();
    }, [match.params.farmId]);

    useEffect(() => {
        const farms = units?.data?.farms?.filter(farm => {
            return farm.parent === farmInfo?.data?.parent?.id;
        });

        const index = findIndex(farms, o => {
            return o.id === match.params.farmId;
        });

        if (farms?.length > 0) {
            let i = index;

            if (i === 0) {
                i = farms.length;
            }
            i -= 1;
            setPreviousFarmId(farms[i]);
        }

        if (farms?.length > 1) {
            let i = index;

            i += 1;
            if (i === farms.length) {
                i = 0;
            }
            setNextFarmId(farms[i]);
        }
    }, [props.units, props.farmInfo]);

    const isEmpty = !cycles.data?.items?.length

    return (
        farmInfo.status === ActionType.Fulfilled &&
        cycles.status === ActionType.Fulfilled &&
        units.status === ActionType.Fulfilled && (
            <>
                <BreadcrumbsFarm farmId={farmInfo.data.id} />
                <Navigation>
                    {previousFarmId && (
                        <FarmLink
                            {...farmInfo.data}
                            id={previousFarmId.id}
                            name={
                                <div>
                                    <Icon type="left" /> {previousFarmId.name}
                                </div>
                            }
                        />
                    )}
                    {nextFarmId && (
                        <FarmLink
                            {...farmInfo.data}
                            id={nextFarmId.id}
                            name={
                                <div>
                                    {nextFarmId.name} <Icon type="right" />
                                </div>
                            }
                        />
                    )}
                </Navigation>
                <StyledContent key="major2">
                    {cycles.data.items.length > 0 && (
                        <HeaderBar>
                            <Title>{farmInfo.data.name}</Title>
                            <Select
                                showSearch
                                style={{width: 300}}
                                placeholder="Выберите цикл"
                                optionFilterProp="children"
                                onChange={handleCycleChange}
                                value={cycle}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cycles.data.items.map(el => (
                                    <Option value={el.id} key={el.id}>
                                        {cycleName(el)}
                                    </Option>
                                ))}
                            </Select>
                        </HeaderBar>
                    )}
                        <Switch>
                            <Route
                                path={`${path}/:tab?`}
                                render={({ match, history }) => {
                                    return (
                                        <>
                                            <Tabs type="card" destroyInactiveTabPane defaultActiveKey={match.params.tab}  onChange={key => {
                                                history.push(`${url}/${key}`);
                                            }}
                                            >
                                                
                                                <TabPane tab="Общая информация" key="home">
                                                    <ModernFarmInformation data={farmInfo.data} />
                                                </TabPane>
                                                <TabPane tab="Таблица" key="homeOld">
                                                    <FarmInformation data={farmInfo.data} />
                                                </TabPane>
                                                {isEmpty ||
                                                        <TabPane tab="График" key="charts">
                                                            <Metrics data={farmInfo.data} cycle={cycle} />
                                                        </TabPane>
                                                    }
                                                {/* {isEmpty ||
                                                        <TabPane tab="Метрики" key="metrics">
                                                            <MultiMetrics data={farmInfo.data} cycle={cycle} />
                                                        </TabPane>
                                                    } */}
                                                <TabPane
                                                    tab={
                                                        <Badge count={farmInfo.data.unresolvedAlarmCount} offset={[10, 0]} dot>
                                                            <span>Тревоги </span>
                                                        </Badge>
                                                    }
                                                    key="alarms"
                                                >
                                                    <AlarmComponent compact id={props.match.params.farmId} />
                                                </TabPane>
                                                {/*<TabPane tab="Поручения" key="tasks">*/}
                                                {/*    <Task id={props.match.params.farmId} />*/}
                                                {/*</TabPane>*/}
                                                {/* <TabPane tab="Покупки" key="com5"> */}
                                                {/*    <Purchase /> */}
                                                {/* </TabPane> */}
                                            </Tabs>
                                        </>
                                     );
                                }}
                            />
                        </Switch>
                </StyledContent>
            </>
        )
    );
};

const mapStateToProps = state => ({
    farmInfo: state.house.farmInfo,
    cycles: state.house.cycles,
    cycle: state.house.cycle,
    units: state.units,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getFarmCycles,
            getFarmInfo,
            setSelectedCycle,
            openCycle,
            closeCycle,
        },
        dispatch,
    ),
});

export const House = connect(mapStateToProps, mapDispatchToProps)(HouseRaw);
