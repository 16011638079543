import React, {useEffect, useRef, useMemo} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {ru} from 'date-fns/locale';
import {format} from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
);

const darkThemeColors = {
    backgroundColor: '#2a2a2b',
    textColor: '#E0E0E3',
    gridColor: '#707073',
    borderColor: '#707073'
};

const defaultThemeColors = {
    backgroundColor: '#ffffff',
    textColor: '#333333',
    gridColor: '#e6e6e6',
    borderColor: '#cccccc'
};

const getDayFromCycleStart = (date, firstDataPoint) => {
    const diffTime = Math.abs(new Date(date) - new Date(firstDataPoint));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

export const Chart = ({data = [], darkTheme, house}) => {
    const chartRef = useRef(null);
    const colors = darkTheme ? darkThemeColors : defaultThemeColors;
    const firstDate = useMemo(() => Math.min(...data.map(series => series.data[0]?.[0] || 0)), [data]);

    const chartData = useMemo(() => ({
        datasets: data.map((series, index) => ({
            label: series.name,
            data: series.data.map(point => ({
                x: new Date(point[0]),
                y: point[1]
            })),
            borderColor: `hsl(${index * 45}, 70%, 50%)`,
            backgroundColor: `hsla(${index * 45}, 70%, 50%, 0.5)`,
            borderWidth: 1,
            tension: 0.4,
            pointRadius: 2,
            // Отключаем анимацию для большей производительности
            animation: false,
            // Оптимизация отрисовки точек
            pointHoverRadius: 4,
            pointHitRadius: 4,
            // Уменьшаем количество точек при отдалении
            decimation: {
                enabled: true,
                algorithm: 'min-max'
            }
        }))
    }), [data]);

    // Мемоизируем опции
    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        animation: false, // Отключаем анимацию
        responsiveAnimationDuration: 0, // Отключаем анимацию при ресайзе
        interaction: {
            mode: 'index',
            intersect: false,
        },
        elements: {
            line: {
                tension: 0.4 // Уменьшаем сглаживание линий
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    color: colors.textColor,
                    padding: 20
                }
            },
            title: {
                display: true,
                text: house,
                color: colors.textColor
            },
            tooltip: {
                enabled: true,
                animation: false,
                position: 'nearest',
                callbacks: {
                    title: function(context) {
                        const dataPoint = context[0];
                        const dateStr = format(new Date(dataPoint.raw.x), 'dd.MM.yyyy, HH:mm:ss');
                        return dateStr;
                    },
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y.toFixed(2)}`;
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    threshold: 10,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                    drag: {
                        enabled: true,
                        backgroundColor: 'rgba(225,225,225,0.3)',
                        borderColor: 'rgba(225,225,225)',
                        borderWidth: 1,
                        threshold: 10,
                        modifierKey: /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'meta' : 'ctrl',
                    },
                    onDrag: ()=>{
                        chartRef.current.update();
                    }
                },
                limits: {
                    x: {min: 'original', max: 'original'}
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    displayFormats: {
                        millisecond: 'HH:mm:ss',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                        day: 'день'
                    }
                },
                ticks: {
                    source: 'auto',
                    autoSkip: true,
                    autoSkipPadding: 30,
                    maxRotation: 0,
                    callback: function(value, index, ticks) {
                        const chart = this.chart;
                        const diff = chart.scales.x.max - chart.scales.x.min;
                        const daysInView = diff / (1000 * 60 * 60 * 24);
                        
                        if (daysInView <= 1) {
                            this.chart.options.scales.x.time.unit = 'hour';
                            return format(new Date(value), 'HH:mm');
                        } else if (daysInView <= 3) {
                            this.chart.options.scales.x.time.unit = 'hour';
                            const cycleDay = getDayFromCycleStart(value, firstDate);
                            return `${cycleDay}д ${format(new Date(value), 'HH:mm')}`;
                        } else {
                            this.chart.options.scales.x.time.unit = 'day';
                            const cycleDay = getDayFromCycleStart(value, firstDate);
                            return `${cycleDay} день`;
                        }
                    },
                    color: colors.textColor
                },
                grid: {
                    color: colors.gridColor,
                    tickLength: 0
                },
                border: {
                    color: colors.borderColor
                }
            },
            y: {
                grid: {
                    color: colors.gridColor
                },
                ticks: {
                    color: colors.textColor
                },
                border: {
                    color: colors.borderColor
                }
            }
        }
    }), [colors, firstDate]);

    const handleZoomClick = (period) => {
        const chart = chartRef.current;
        if (!chart) return;

        const now = new Date();
        let startDate = new Date();

        switch(period) {
            case 'day':
                startDate.setHours(0, 0, 0, 0);
                break;
            case 'week':
                startDate.setDate(now.getDate() - 7);
                break;
            case 'cycle':
                startDate = new Date(firstDate);
                break;
            default:
                return;
        }

        // Используем официальный метод zoomScale
        chart.zoomScale('x', {
            min: startDate.getTime(),
            max: now.getTime()
        }, 'none');
        chart.update();
    };

    const handleDownload = () => {
        const chart = chartRef.current;
        if (!chart) return;

        // Создаем временный canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = chart.canvas.width;
        tempCanvas.height = chart.canvas.height;
        const tempCtx = tempCanvas.getContext('2d');

        // Заполняем белым фоном
        tempCtx.fillStyle = colors.backgroundColor;
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
        
        // Копируем содержимое оригинального canvas
        tempCtx.drawImage(chart.canvas, 0, 0);

        // Получаем URL изображения с белым фоном
        const imageURL = tempCanvas.toDataURL('image/png');

        // Создаем ссылку для скачивания
        const downloadLink = document.createElement('a');
        downloadLink.href = imageURL;
        downloadLink.download = `chart-${new Date().toISOString()}.png`;
        
        // Эмулируем клик для скачивания
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div style={{
            width: '100%',
            backgroundColor: colors.backgroundColor
        }}>
            <div style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${colors.borderColor}`
            }}>
                <div style={{
                    display: 'flex',
                    gap: '8px'
                }}>
                    <button 
                        onClick={() => handleZoomClick('day')}
                        style={{
                            padding: '4px 12px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        День
                    </button>
                    <button 
                        onClick={() => handleZoomClick('week')}
                        style={{
                            padding: '4px 12px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Неделя
                    </button>
                    <button 
                        onClick={() => handleZoomClick('cycle')}
                        style={{
                            padding: '4px 12px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Цикл
                    </button>
                </div>
                <div>
                    <button 
                        onClick={handleDownload}
                        style={{
                            padding: '4px 12px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Скачать
                    </button>
                </div>
            </div>
            <div style={{
                padding: '16px 0',
                width: '100%',
                height: '700px',
            }}>
                <Line 
                    ref={chartRef} 
                    options={options} 
                    data={chartData}
                    redraw={false}
                />
            </div>
        </div>
    );
}; 