import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import sortBy from 'lodash/sortBy';
import {handleApiActions, initialState} from '../../utils';
import {priorityList} from '../../../utils';

const GET_FARMS_BY_FARMGROUP = 'GET_FARMS_BY_FARMGROUP';
const CLEAR_FARMS = 'CLEAR_FARMS';

export const getFarms = createAction(
    GET_FARMS_BY_FARMGROUP,
    id =>
        new Promise((res, rej) => {
            fetcher
                .get(`/farms?farmGroupId=${id}&sortBy=sortOrder&size=100&offset=0`)
                .then(data => {
                    data.items.map(dep => {
                        if (dep.state) {
                            const mapped = Object.entries(dep.state.metrics).map(([el, val], index) => ({key: el, ...val}));
                            dep.state.metricsList = sortBy(mapped, val => priorityList(val));
                        } else {
                            dep.state = {
                                metricsList: [],
                            };
                        }
                    });
                    res(data);
                })
                .catch(err => rej(err));
        }),
);
export const clearFarms = createAction(CLEAR_FARMS);

export const farmsReducer = handleApiActions(
    GET_FARMS_BY_FARMGROUP,
    {
        [CLEAR_FARMS]: () => initialState,
    },
    initialState,
);
