import React, {useEffect, useState} from 'react';
import {Button, Icon, Modal, Pagination, Select, Spin, Table, Typography} from 'antd';
import {ActionType} from 'redux-promise-middleware';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import isEmpty from 'lodash/isEmpty';

import {Link} from 'react-router-dom';
import {ActionLink, HeaderPart} from '../Styled';
import {createUser, getUsers, updateUser, updateUserPassword} from '../../../reduxStore/modules/admin/users';
import {CreateUserForm} from '../../../components/Forms/UserForms/CreateUserForm';
import {ChangePasswordForm} from '../../../components/Forms/UserForms/PasswordForm';
import {PAGES} from '../../../commonConstants/constants';

const {Title} = Typography;

const UsersPageRaw = props => {
    const [visibleModal, setVisability] = useState(false);
    const [visiblePasswordModal, setVisiblePasswordModal] = useState(false);
    const [editable, setEditable] = useState({});
    const [currentCompanyId, setCurrentCompanyId] = useState('');
    const {Option} = Select;
    const [error, setError] = useState(false);

    function handleCompanyChange(value) {
        setCurrentCompanyId(value);
    }

    const toogleModalState = () => {
        if (visibleModal) {
            setEditable({});
        }
        setVisability(!visibleModal);
    };

    const tooglePasswordModalState = () => {
        if (visiblePasswordModal) {
            setEditable({});
        }
        setVisiblePasswordModal(!visiblePasswordModal);
    };

    const onEditClick = (e, record) => {
        e.stopPropagation();
        setEditable(record);
        toogleModalState();
    };

    const onPasswordChangeClick = (e, record) => {
        e.stopPropagation();
        setEditable(record);
        tooglePasswordModalState();
    };

    const columns = [
        {
            title: 'Имя',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (text, record) => (
                <>
                    <Link to={`/${PAGES.ADMIN}/users/${record.id}`}>{record.fullName}</Link>
                </>
            ),
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: 'Должность',
            dataIndex: 'companyPosition',
            key: 'companyPosition',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Действия',
            key: 'operation',
            fixed: 'right',
            width: 250,
            render: (text, record) => (
                <>
                    <ActionLink onClick={e => onEditClick(e, record)}>
                        <Icon type="edit" />
                    </ActionLink>
                    <ActionLink onClick={e => onPasswordChangeClick(e, record)}>Сменить пароль</ActionLink>
                </>
            ),
        },
    ];

    const {users, actions, companies} = props;

    const fetchUsers = () => {
        if (currentCompanyId) {
            actions.getUsers({size: 100, offset: 0, company: currentCompanyId});
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [currentCompanyId]);

    const onSubmitForm = values => {
        setError(false);

        values.phoneNumber = values?.phoneNumber?.replaceAll(/[\s\-()]+/g, '')
        if (isEmpty(editable)) {
            actions
                .createUser(values)
                .then(() => {
                    toogleModalState();
                    fetchUsers();
                })
                .catch(err => {
                    if (err?.response?.data?.message) {
                        setError(err.response.data.message);
                    } else {
                        setError('Неправильный логин или пароль');
                    }
                    console.log(err);
                });
        } else {
            actions
                .updateUser({id: editable.id, data: values})
                .then(() => {
                    toogleModalState();
                    fetchUsers();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const onSubmitPasswordChange = values => {
        actions
            .updateUserPassword({id: editable.id, data: values})
            .then(() => {
                tooglePasswordModalState();
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <Spin spinning={ActionType.Fulfilled !== companies.status}>
            <HeaderPart>
                <Title>Пользователи</Title>
                <Button type="primary" onClick={toogleModalState}>
                    Создать нового
                </Button>
                <Modal
                    visible={visibleModal}
                    title="Создание пользователя"
                    footer={null}
                    onCancel={toogleModalState}
                    destroyOnClose
                >
                    <CreateUserForm
                        error={error}
                        onSubmit={onSubmitForm}
                        initialValues={editable}
                        edit={isEmpty(editable)}
                        companies={companies?.data?.content || []}
                    />
                </Modal>
                <Modal
                    visible={visiblePasswordModal}
                    title="Изменение пароля"
                    footer={null}
                    onCancel={tooglePasswordModalState}
                    destroyOnClose
                >
                    <ChangePasswordForm onSubmit={onSubmitPasswordChange} />
                </Modal>
            </HeaderPart>

            <Select placeholder="Фабрика" style={{width: 240}} onChange={handleCompanyChange}>
                {companies?.data?.content?.map(company => (
                    <Option value={company.id}>{company.name}</Option>
                ))}
            </Select>
            <Table
                dataSource={users?.data?.content || []}
                columns={columns}
                pagination={false}
                rowKey={record => `row-${record.id}`}
            />
            <Pagination onChange={onchange} defaultCurrent={1} total={50} />
        </Spin>
    );
};

const mapStateToProps = state => ({
    users: state.admin.users,
    companies: state.admin.companies,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getUsers,
            createUser,
            updateUser,
            updateUserPassword,
        },
        dispatch,
    ),
});

export const UsersPage = connect(mapStateToProps, mapDispatchToProps)(UsersPageRaw);
