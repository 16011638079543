import find from 'lodash/find';

export const TOTAL_HEAD_OF_LIVESTOCK = {
    good: 90,
    normal: 60,
};

export const PAGES = {
    DEPARTMENT: 'departments',
    FARMGROUP: 'farmgroups',
    FARM: 'farms',
    ALARM: 'alarms',
    SIGNIN: 'auth',
    USER: 'user',
    CONTACTS: 'contacts',
    COMPARISON: 'compare',
    ADMIN: 'admin',
    USERS: 'users',
};

export const ALARM_STATUS = {
    DOING: 'in_proccess',
    DONE: 'resolved',
    OPEN: 'open',
};

export const TASK_STATUS = {
    DOING: 'in_progress',
    DONE: 'done',
    OPEN: 'open',
};

export const METRICS = {
    // Климат
    temperature_in: {
        label: 'Температура внутри',
        count: '℃',
        icon: 'dashboard',
        category: 'climate'
    },
    temperature_out: {
        label: 'Температура снаружи',
        count: '℃',
        icon: 'dashboard',
        category: 'climate'
    },
    preset_temperature: {
        label: 'Температура установки',
        count: '℃',
        icon: 'setting',
        category: 'climate'
    },
    humidity: {
        label: 'Влажность',
        count: '%',
        icon: 'cloud',
        category: 'climate'
    },
    akbars_co2: {
        label: 'CO₂',
        count: 'ppm',
        icon: 'experiment',
        category: 'climate'
    },
    akbars_pressure: {
        label: 'Давление',
        count: 'Па',
        icon: 'arrow-up',
        category: 'climate'
    },
    cooling: {
        label: 'Охлаждение',
        count: '%',
        icon: 'thunderbolt',
        category: 'climate'
    },

    // Вентиляция
    akbars_airing: {
        label: 'Вентиляция',
        count: '%',
        icon: 'sync',
        category: 'ventilation'
    },
    common_air_inflow: {
        label: 'Приток (форточки)',
        count: '%',
        icon: 'arrow-up',
        category: 'ventilation'
    },
    recuperator: {
        label: 'Рекуператор',
        count: '%',
        icon: 'reload',
        category: 'ventilation'
    },
    akbars_left_tunnel_leaf: {
        label: 'Левая тун. форточка',
        count: '%',
        icon: 'arrow-left',
        category: 'ventilation'
    },
    akbars_right_tunnel_leaf: {
        label: 'Правая тун. форточка',
        count: '%',
        icon: 'arrow-right',
        category: 'ventilation'
    },
    akbars_left_inflow: {
        label: 'Левый приток',
        count: '%',
        icon: 'arrow-left',
        category: 'ventilation'
    },
    akbars_right_inflow: {
        label: 'Правый приток',
        count: '%',
        icon: 'arrow-right',
        category: 'ventilation'
    },
    akbars_performance: {
        label: 'Производительность',
        count: 'м³/ч',
        icon: 'bar-chart',
        category: 'ventilation'
    },

    // Поголовье кур
    chicken_livestock: {
        label: 'Поголовье кур',
        count: 'шт.',
        icon: 'team',
        category: 'population'
    },
    chicken_livestock_on_cycle_start: {
        label: 'Кур на посадке',
        count: 'шт.',
        icon: 'team',
        category: 'population'
    },
    chicken_mortality: {
        label: 'Падёж кур',
        count: 'шт.',
        icon: 'warning',
        category: 'population'
    },
    chicken_mortality_today: {
        label: 'Падёж кур (сегодня)',
        count: 'шт.',
        icon: 'warning',
        category: 'population'
    },
    chicken_weight: {
        label: 'Вес курицы',
        count: 'кг',
        icon: 'dashboard',
        category: 'population'
    },
    chicken_feed_daily_preset_g: {
        label: 'Установка корма кур',
        count: 'г',
        icon: 'setting',
        category: 'feeding'
    },

    // Поголовье петухов
    cock_livestock: {
        label: 'Поголовье петухов',
        count: 'шт.',
        icon: 'team',
        category: 'population'
    },
    cock_livestock_on_cycle_start: {
        label: 'Петухов на посадке',
        count: 'шт.',
        icon: 'team',
        category: 'population'
    },
    cock_mortality: {
        label: 'Падёж петухов',
        count: 'шт.',
        icon: 'warning',
        category: 'population'
    },
    cock_mortality_today: {
        label: 'Падёж петухов (сегодня)',
        count: 'шт.',
        icon: 'warning',
        category: 'population'
    },
    cock_weight: {
        label: 'Вес петуха',
        count: 'кг',
        icon: 'dashboard',
        category: 'population'
    },
    cock_feed_daily_preset_g: {
        label: 'Установка корма петухов',
        count: 'г',
        icon: 'setting',
        category: 'feeding'
    },

    // Кормление и вода
    today_water_consumption: {
        label: 'Расход воды (сегодня)',
        count: 'л',
        icon: 'experiment',
        category: 'feeding'
    },
    water_consumption_per_bird_today_ml: {
        label: 'Расход воды на птицу (сегодня)',
        count: 'мл',
        icon: 'experiment',
        category: 'feeding'
    },

    // Производство
    egg_counter: {
        label: 'Счётчик яйца',
        count: 'шт.',
        icon: 'inbox',
        category: 'production'
    },

    // Управление
    akbars_mphase: {
        label: 'М-фаза',
        count: '',
        icon: 'setting',
        category: 'other'
    }
};

export const METRIC_CATEGORIES = {
    climate: {
        label: 'Климат',
        icon: 'cloud'
    },
    ventilation: {
        label: 'Вентиляция',
        icon: 'sync'
    },
    population: {
        label: 'Поголовье',
        icon: 'team'
    },
    feeding: {
        label: 'Кормление',
        icon: 'shopping'
    },
    production: {
        label: 'Производство',
        icon: 'inbox'
    },
    other: {
        label: 'Прочие показатели',
        icon: 'bar-chart'
    }
};

const defaultGraphicsValues = [
    'humidity',
    'temperature_out',
    'temperature_in',
    'chicken_mortality',
    'akbars_tunnel_leaf',
    'akbars_pressure',
];

const farmTypes = ['broiler', 'repair_young'];

export const getDefaultGraphicsValues = metricsList => {
    return defaultGraphicsValues.filter(el => {
        return find(metricsList, ['value', el]);
    });
};
