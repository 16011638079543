import React, {useEffect, useRef, useMemo} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {ru} from 'date-fns/locale';
import {format, differenceInDays} from 'date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    zoomPlugin
);

const darkThemeColors = {
    backgroundColor: '#2a2a2b',
    textColor: '#E0E0E3',
    gridColor: '#707073',
    borderColor: '#707073'
};

const defaultThemeColors = {
    backgroundColor: '#ffffff',
    textColor: '#333333',
    gridColor: '#e6e6e6',
    borderColor: '#cccccc'
};

export const Hichart = ({data = [], darkTheme}) => {
    const chartRef = useRef(null);
    const colors = useMemo(() => darkTheme ? darkThemeColors : defaultThemeColors, [darkTheme]);
    const firstDate = useMemo(() => Math.min(...data.map(series => series.data[0]?.[0] || 0)), [data]);

    const chartData = useMemo(() => ({
        datasets: data.map((series, index) => ({
            label: series.name,
            data: series.data.map(point => ({
                x: new Date(point[0]),
                y: point[1]
            })),
            borderColor: `hsl(${index * 45}, 70%, 50%)`,
            backgroundColor: `hsla(${index * 45}, 70%, 50%, 0.5)`,
            borderWidth: 1,
            tension: 0.4,
            pointRadius: 2,
            animation: false,
            pointHoverRadius: 4,
            pointHitRadius: 4,
            decimation: {
                enabled: true,
                algorithm: 'min-max'
            }
        }))
    }), [data]);

    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        responsiveAnimationDuration: 0,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        elements: {
            line: {
                tension: 0.4
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    color: colors.textColor,
                    padding: 20
                }
            },
            title: {
                display: true,
                text: 'График',
                color: colors.textColor
            },
            tooltip: {
                enabled: true,
                animation: false,
                position: 'nearest',
                callbacks: {
                    title: function(context) {
                        const dataPoint = context[0];
                        const dayNumber = differenceInDays(new Date(dataPoint.raw.x), new Date(firstDate)) + 1;
                        return `${dayNumber}-й день\n${format(new Date(dataPoint.raw.x), 'dd.MM.yyyy, HH:mm:ss', {locale: ru})}`;
                    },
                    label: function(context) {
                        return `${context.dataset.label}: ${context.parsed.y.toFixed(2)}`;
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x',
                    threshold: 10,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x',
                    drag: {
                        enabled: true,
                        backgroundColor: colors.backgroundColor === '#ffffff' ? 'rgba(225,225,225,0.3)' : 'rgba(70,70,70,0.3)',
                        borderColor: colors.borderColor,
                        borderWidth: 1,
                        threshold: 10,
                        modifierKey: /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'meta' : 'ctrl',
                    }
                },
                limits: {
                    x: {min: 'original', max: 'original'}
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                grid: {
                    color: colors.gridColor,
                    drawOnChartArea: true
                },
                ticks: {
                    color: colors.textColor,
                    callback: function(value) {
                        const dayNumber = differenceInDays(new Date(value), new Date(firstDate)) + 1;
                        return `${dayNumber}-й день`;
                    }
                },
                border: {
                    color: colors.borderColor
                }
            },
            y: {
                grid: {
                    color: colors.gridColor,
                    drawOnChartArea: true
                },
                ticks: {
                    color: colors.textColor
                },
                border: {
                    color: colors.borderColor
                }
            }
        }
    }), [colors, firstDate]);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.update();
        }
    }, [darkTheme]);

    const handleZoomClick = (period) => {
        const chart = chartRef.current;
        if (!chart) return;

        const now = new Date();
        let startDate = new Date();

        switch(period) {
            case 'day':
                startDate.setDate(now.getDate() - 3);
                break;
            case 'week':
                startDate.setDate(now.getDate() - 7);
                break;
            case 'cycle':
                startDate = new Date(firstDate);
                break;
            default:
                return;
        }

        chart.zoomScale('x', {
            min: startDate.getTime(),
            max: now.getTime()
        }, 'none');
        chart.update();
    };

    return (
        <div style={{
            backgroundColor: colors.backgroundColor,
            height: '100%',
            padding: '16px',
            borderRadius: '4px'
        }}>
            <div style={{
                padding: '8px',
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${colors.borderColor}`
            }}>
                <div>
                    <button 
                        onClick={() => handleZoomClick('day')}
                        style={{
                            padding: '4px 12px',
                            marginRight: '8px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        День
                    </button>
                    <button 
                        onClick={() => handleZoomClick('week')}
                        style={{
                            padding: '4px 12px',
                            marginRight: '8px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Неделя
                    </button>
                    <button 
                        onClick={() => handleZoomClick('cycle')}
                        style={{
                            padding: '4px 12px',
                            backgroundColor: colors.backgroundColor,
                            color: colors.textColor,
                            border: `1px solid ${colors.borderColor}`,
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Цикл
                    </button>
                </div>
            </div>
            <div style={{
                padding: '16px 0',
                width: '100%',
                height: '700px',
            }}>
                <Line 
                    ref={chartRef} 
                    options={options} 
                    data={chartData}
                    redraw={false}
                />
            </div>
        </div>
    );
};
