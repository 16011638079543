import React, {useState} from 'react';
import styled from 'styled-components';
import {Switch} from 'antd';
import {MetricsList} from './MetricsList';
import {useStateValue} from '../../store/Provider';
// import {Hichart} from './Highcharts';
import {Chart} from './Chart';

const ControlPanel = styled.div`
    display: flex;
    justify-content: space-between;
`;

const GraphWrapper = styled.div``;

export const Metrics = props => {
    const [{farm}] = useStateValue();
    const [darkTheme, setDarkTheme] = useState(false);

    return (
        <GraphWrapper>
            <ControlPanel>
                <MetricsList key={props.cycle} data={props.data.state.metrics} farmId={props.data.id} />
                <span>
                    Темная тема <Switch onChange={setDarkTheme} checked={darkTheme} />
                </span>
            </ControlPanel>
            <br />
            {/* <Hichart data={farm.metrics} darkTheme={darkTheme} key={darkTheme ? 'dark' : 'default'} /> */}
            <Chart data={farm.metrics} darkTheme={darkTheme} house={props.data.name}/>
        </GraphWrapper>
    );
};
