import React, { useEffect, useState } from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { priorityList } from '../../utils';
import styled from 'styled-components';
import { Card, Row, Col, Statistic, Timeline, Typography, Icon, Tooltip } from 'antd';
import { METRICS, METRIC_CATEGORIES } from '../../commonConstants/constants';

const { Title } = Typography;

const DashboardWrapper = styled.div`
  padding: 20px;
  
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
    transition: all 0.3s ease;
  }
`;

const MetricCard = styled(StyledCard)`
  background: ${props => props.background || '#fff'};
  color: ${props => props.color || 'inherit'};
  height: 100%;
  margin-bottom: 0;
  
  .ant-card-body {
    padding: 12px;
  }
  
  .ant-statistic {
    .ant-statistic-title {
      font-size: 14px;
      text-align: left;
      margin-bottom: 8px;
      min-height: 42px;
    }
    .ant-statistic-content {
      font-size: 20px;
      text-align: left;
      .anticon {
        margin-right: 8px;
        font-size: 16px;
      }
    }
  }
`;

const CategoryTitle = styled(Title)`
  margin: 24px 0 16px 0 !important;
  font-size: 18px !important;
  text-align: left !important;
`;

const CATEGORY_ORDER = [
  'time',
  'population',
  'climate',
  'ventilation',
  'production',
  'feeding',
  'other'
];

const getIconForMetric = (metricKey, metricName) => {
  // Если метрика есть в списке констант, берем иконку оттуда
  if (METRICS[metricKey]) {
    return <Icon type={METRICS[metricKey].icon} />;
  }
  
  // Для базовых метрик и неизвестных
  if (metricName.toLowerCase().includes('день')) return <Icon type="clock-circle" />;
  if (metricName.toLowerCase().includes('обновление')) return <Icon type="sync" />;
  return <Icon type="bar-chart" />;
};

const getCategoryForMetric = (metricKey, metricName) => {
  // Если метрика есть в списке констант, берем категорию оттуда
  if (METRICS[metricKey]) {
    return METRICS[metricKey].category;
  }
  
  // Для базовых метрик
  if (metricName.toLowerCase().includes('день') || metricName.toLowerCase().includes('обновление')) {
    return 'time';
  }
  return 'other';
};

export const ModernFarmInformation = ({ data: farm }) => {
  const [groupedMetrics, setGroupedMetrics] = useState({});
  const farmMetrics = farm.state?.metrics || [];

  useEffect(() => {
    const mapped = Object.entries(farmMetrics).map(([key, val]) => ({
      key,
      ...val
    }));

    const baseMetrics = [
      {
        label: 'День цикла',
        value: farm.cycleDay,
        color: '#1890ff',
        category: 'time',
        important: true
      },
      {
        label: 'Последнее обновление',
        value: farm.state?.updatedAt 
          ? moment(farm.state.updatedAt).fromNow()
          : 'Ошибка',
        tooltip: farm.state?.updatedAt 
          ? moment(farm.state.updatedAt).format('DD.MM.YYYY HH:mm:ss')
          : 'Ошибка',
        color: '#52c41a',
        category: 'time',
        important: true
      }
    ];

    const allMetrics = [...baseMetrics, ...sortBy(mapped, val => priorityList(val))];
    
    // Группируем метрики по категориям
    const grouped = allMetrics.reduce((acc, metric) => {
      const category = metric.category || getCategoryForMetric(metric.key, metric.label);
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(metric);
      return acc;
    }, {});

    setGroupedMetrics(grouped);
  }, [farm]);

  const categoryTitles = {
    time: 'Временные показатели',
    ...Object.entries(METRIC_CATEGORIES).reduce((acc, [key, value]) => {
      acc[key] = value.label;
      return acc;
    }, {})
  };

  const renderMetricCards = (metrics) => (
    <Row gutter={[16, 16]}>
      {metrics.map((metric, index) => (
        <Col xs={24} sm={12} md={8} lg={6} key={index}>
          {metric.tooltip ? (
            <Tooltip title={metric.tooltip}>
              <MetricCard>
                <Statistic
                  title={metric.label}
                  value={metric.value}
                  prefix={getIconForMetric(metric.key, metric.label)}
                  valueStyle={{ color: metric.color }}
                />
              </MetricCard>
            </Tooltip>
          ) : (
            <MetricCard>
              <Statistic
                title={metric.label}
                value={metric.value}
                prefix={getIconForMetric(metric.key, metric.label)}
                valueStyle={{ color: metric.color }}
              />
            </MetricCard>
          )}
        </Col>
      ))}
    </Row>
  );

  return (
    <DashboardWrapper>
      <Title level={2}>Информация по корпусу</Title>

      {/* Отображаем метрики в заданном порядке */}
      {CATEGORY_ORDER.map(category => {
        const metrics = groupedMetrics[category] || [];
        if (metrics.length === 0) return null;

        const importantMetrics = metrics.filter(m => m.important);
        const regularMetrics = metrics.filter(m => !m.important);

        return (
          <div key={category}>
            <CategoryTitle level={3}>{categoryTitles[category]}</CategoryTitle>
            {importantMetrics.length > 0 && renderMetricCards(importantMetrics)}
            {regularMetrics.length > 0 && renderMetricCards(regularMetrics)}
          </div>
        );
      })}
    </DashboardWrapper>
  );
}; 