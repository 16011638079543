import React from 'react';
import {Switch, Route, Redirect} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import 'antd/dist/antd.less';
import {StateProvider} from 'store/Provider';
import Cookies from 'js-cookie';

import {ConfigProvider} from 'antd';
import ruRu from 'antd/es/locale/ru_RU';

// import ru from 'antd/lib/locale-provider/ru_RU';
import {SignIn} from './components/SignIn';
import {MainLayout} from './components/Layout';
import {Main} from './pages/Main';
import {Department} from './pages/Department';
import {House} from './pages/House';
import {Alarm} from './pages/Alarm';
import {PAGES} from './commonConstants/constants';
import {Farmgroup} from './pages/Farmgroup';
import {Contacts} from './pages/Contacts';
import {ComparisonPage} from './pages/Comparison';
import {BASENAME, formRedirect} from './rest';
import {Admin} from './pages/Admin';

const App = () => (
    <ConfigProvider locale={ruRu}>
        <div className="App">
            <StateProvider>
                <BrowserRouter basename={BASENAME}>
                    <Switch>
                        <Route exact path={`/${PAGES.SIGNIN}`} component={SignIn} />
                        <PrivateRoute exact path="/" component={Main} />
                        <PrivateRoute exact path={`/${PAGES.DEPARTMENT}/:id`} component={Department} />
                        <PrivateRoute exact path={`/${PAGES.FARMGROUP}/:farmgroupId`} component={Farmgroup} />
                        <PrivateRoute path={`/${PAGES.FARM}/:farmId`} component={House} />
                        <PrivateRoute exact path={`/${PAGES.ALARM}`} component={Alarm} />
                        {/* <PrivateRoute exact path={`/${PAGES.ALARM}/new`} component={CreateAlarm} /> */}
                        <PrivateRoute exact path={`/${PAGES.COMPARISON}`} component={ComparisonPage} />
                        <PrivateRoute exact path={`/${PAGES.CONTACTS}`} component={Contacts} />
                        <PrivateRoute path={`/${PAGES.ADMIN}`} component={Admin} />
                        <Route component={() => <Redirect to="/" />} />
                    </Switch>
                </BrowserRouter>
            </StateProvider>
        </div>
    </ConfigProvider>
);

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            Cookies.get('authorization') ? (
                <MainLayout {...props}>
                    <Component {...props} />
                </MainLayout>
            ) : (
                <RedirectWithParams {...props} />
            )
        }
    />
);

const RedirectWithParams = () => {
    formRedirect();
    return <Redirect to={`/${PAGES.SIGNIN}`} />;
};

export default App;
